<template>
	<div class="article-card">
		<div class="article-card__poster" :class="config.class">
			<tk-image
				:src="content.preview"
				:alt="content.alias"
        :width="config.size.width"
        :height="config.size.height"
			/>
		</div>
		<div class="article-card__content">
			<h3 class="article-card__hdng">{{ content.title }}</h3>

			<tk-button
				@click="$router.push({ path: '/article/' + content.alias })"
				class="article-card__read"
				kind="main-white"
			>
				Читать
			</tk-button>
		</div>
	</div>
</template>

<script>
const _config = {
  big: {
    class: 'big',
    size: {
      width: 590,
      height: 347
    }
  },
  small: {
    class: 'small',
    size: {
      width: 383,
      height: 347
    }
  }
}

export default {
	props: {
		type: {
			type: String,
			default: 'big' // big/small
		},
		content: {
			type: Object,
			default: () => ({})
		}
	},
  computed: {
    config() {
      return _config[this.type]
    }
  }
}
</script>

<style lang="sass">
.article-card
  position: relative
  margin-right: 5px
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))
  border-radius: 10px
  overflow: hidden

  &__poster
    display: flex
    align-items: center
    justify-content: center
    overflow: hidden
    border-radius: 10px
    position: relative
    z-index: -1
    img
      object-fit: cover
  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    position: absolute
    top: 0
    height: 100%
    padding: 30px 35px 20px 35px

  &__hdng
    margin: 0
    font-weight: 700
    font-size: 28px
    line-height: 33px
    text-align: left
    color: #FFF

  &__read
    display: block
    width: 148px
    margin-top: 20px
    margin-left: 0
    margin-right: auto

  @media screen and (max-width: 700px)
    &__content
      padding: 20px 25px 10px 25px

    &__hdng
      font-size: 18px
      line-height: 22px

    &__read
      margin-top: 10px
</style>
